import { css } from "@emotion/react"
import mq from "./mediaquerys"

export const size = {
  maxWidth: "77rem",
}

export const spacing = {
  section: css`
    margin-top: 10rem;
    ${mq[1]} {
      margin-top: 15rem;
    }
  `,
  paddingSection: css`
    padding-top: 10rem;
    ${mq[1]} {
      padding-top: 15rem;
    }
  `,
}

export const color = {
  // plue colors
  plue_light: "#4A33FF",
  plue700: "#240099",
  plue400: "#4A33FF",
  plue300: "#6B66FF",
  plue200: "#99A0FF",
  plue100: "#CCCFFF",
  plue25: "#F5F5FF",

  white: "#FFFFFF",
  gray25: "#EFEFF5",
  gray100: "#E3E3E8",
  gray200: "#CACACE",
  gray300: "#B0B0B5",
  gray400: "#97979B",
  gray500: "#7D7D82",
  gray600: "#646468",
  gray700: "#4B4B4E",
  gray800: "#313135",
  gray900: "#18181B",
  black: "#000000",

  richBlack: "#010203",
  main_dark: "#121212",
  // main_light: "#fafafa",
  main_light: "#f9f9fb",
  //accent_light: "#eaeaea",
  accent_light: "#EFEFF5",

  red: "#de166b",
  blue: "#4645b9",
  orange: "#ef7800",

  cta_green: "#0cb78b",
  rec: "#ff2d3f",
  plue_rec: "#ff6966",
}

export const fontStyle = {
  copy: {
    regular: css`
      margin-bottom: 0;
      & + p {
        margin-top: 0.5em;
      }
    `,
    landing: css`
      font-size: 1.25rem;
      color: ${color.gray400};
      max-width: 50rem;
    `,
  },
  header: {
    landingSection: css`
      font-size: 3rem;
      font-weight: 600;
      max-width: 35rem;
      line-height: 1.3em;
      margin-bottom: 1.5rem;
      color: white;
    `,
  },
  footer: {
    title: css`
      font-size: 0.85em;
      font-weight: 700;
    `,
    item: css`
      font-size: 0.7em;
      line-height: 1.4em;
      letter-spacing: 0.025em;
      color: ${color.gray500};
      margin-bottom: 0;
      + p {
        padding-top: 0.25em;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    `,
  },
  dropdown: {
    title: css`
      font-size: 0.75em;
      font-weight: 600;
      letter-spacing: 0.035em;
    `,
  },
}

export const fontSize = {
  small: "0.7rem",
}

export const radius = {
  small: "5px",
}
