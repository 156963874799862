import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const Seo = ({ frontmatter = {} }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            author
            cononicalUrl
            description
            image
            social {
              twitter
              instagram
              linkedin
            }
            organization {
              name
              url
              logo
            }
            title
            year
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const pageMeta = frontmatter || {}

      const title = pageMeta.title ? pageMeta.title + " | EXPO2022" : seo.title
      const description = pageMeta.description || seo.description
      const image = pageMeta.image
        ? `${seo.cononicalUrl}${pageMeta.image}`
        : undefined || seo.image
      const url = pageMeta.slug
        ? `${seo.cononicalUrl}/${pageMeta.slug}`
        : seo.cononicalUrl
      return (
        <Helmet>
          {/* General tags */}
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="image" src={image} />
          <link rel="cononical" href={url} />

          {/* OpenGraph tags */}
          <meta property="og:url" content={url} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={seo.social.twitter} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
        </Helmet>
      )
    }}
  />
)

export default Seo
